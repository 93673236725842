/**
 * Adsense
 */

import React, { memo, useEffect } from 'react'
import Const from '../const'

const Adsense = memo(props => {
  useEffect(() => {
    if (window) (window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'inline-block', width: '300px', height: '250px' }}
      data-ad-client={ Const.GOOGLE_AD_CLIENT }
      data-ad-slot={ Const.GOOGLE_AD_SLOT }
    />
  )
})

const parser = typeof DOMParser !== 'undefined' && new DOMParser()

export const insertAdsense = html => {
  if (!parser) return html

  const ad = `
<ins class="adsbygoogle"
  style="display:block; width: 300px; height: 250px;"
  data-ad-client="${ Const.GOOGLE_AD_CLIENT }"
  data-ad-slot="${ Const.GOOGLE_AD_SLOT }"
  data-ad-format="auto"
  data-full-width-responsive="true"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>
`

  const dom = parser.parseFromString(html, 'text/html')
  const eles = [].slice.call(dom.querySelectorAll('.adsense'))

  if (eles) {
    eles.map(ele => (ele.innerHTML = ad))
  }

  return dom.body.innerHTML
}

export default Adsense
