import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Const from '../const'
// components
import { insertAdsense } from '../components/Adsense'
import Header from '../components/Header'
import Tag from '../components/Tag'
import SnsShares from '../components/SnsShares'
import { Container, Layout, LayoutMain, LayoutLeft, LayoutAside } from '../components/Layout'

import './post.scss'


const PostPage = ({ data, location, pathContext }) => {
  const { markdownRemark } = data
  let { frontmatter, fields, html } = markdownRemark
  const { title, date, tags } = frontmatter
  const { slug } = fields

  // amazon associate tag
  html = html.replace(/{{AMZ_TAG}}/g, Const.AMAZON_TAG)

  return (
    <Container className="post">
      <Helmet>
        <title>{ title } | { Const.SITE_NAME }</title>
      </Helmet>

      <Header />

      <Layout className="post__container">
        <LayoutLeft />

        <LayoutMain>
          <div className="note">


            <div className="note__head">
              <h1 className="note__head__title">{ title }</h1>

              <div className="post__tags">
                { tags.map((tagname, i) => {
                    return <Tag key={ i } tagname={ tagname } />
                  })
                }
              </div>

              <SnsShares url={ `${ Const.SITE_URL }${ slug }` } title={ Const.SITE_NAME } description={ title } />
            </div>

            <div className="note__head__memo">
              <div className="note__head__memo__date">
                <time dateTime={ date }>{ date }</time>
              </div>
            </div>

            <div className="note__contents">
              <div
                className="note__contents__markdown"
                dangerouslySetInnerHTML={{ __html: insertAdsense(`${ html }<div class="adsense"></div>`) }}
              />
            </div>
          </div>
        </LayoutMain>

        <LayoutAside />
      </Layout>
    </Container>
  )
}

export const query = graphql`
query($path: String!) {
  markdownRemark(fields: { slug: { eq: $path } }) {
    html
    fields {
      slug
    }
    frontmatter {
      title
      date(formatString: "YYYY/MM/DD")
      tags
    }
  }
}
`
export default PostPage
