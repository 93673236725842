import React, { memo } from 'react'
import { Helmet } from 'react-helmet'
import Const from '../const'
import {
  FacebookShareButton,
  FacebookShareCount,
  LineShareButton,
} from 'react-share'
// style
import './snsshares.scss'

const SnsShares = memo(({ url, title, description }) => {
  return (
    <>
      <Helmet>
        <meta property="og:locale" content="ja_JP" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content={ Const.SITE_NAME } />
        <meta property="og:title" content={ title } />
        <meta property="og:url" content={ url } />
        <meta property="og:description" content={ description } />
        <meta property="og:image" content={ Const.SITE_IMAGE_FACEBOOK } />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@tips_note" />
        <meta name="twitter:title" content={ title } />
        <meta name="twitter:description" content={ description } />
        <meta name="twitter:image" content={ Const.SITE_IMAGE_TWITTER } />
        <script type="text/javascript" src="https://b.st-hatena.com/js/bookmark_button.js" charset="utf-8" async="async"></script>
        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        <script async src="https://widgets.getpocket.com/v1/j/btn.js?v=1" charset="utf-8"></script>
        {/*<script async src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0" charset="utf-8"></script>*/}
      </Helmet>
      <div className="snsshares">
        <div>
          <FacebookShareButton
            url={ url }
            quote={ title }
            className="snsshares__facebook"
          >
            <>
            <span>facebook</span>
            <FacebookShareCount url={url} />
            </>
          </FacebookShareButton>
        </div>

        {/*
        <li>
          <div id="fb-root"></div>
          <div
            className="fb-share-button"
            data-href={ url }
            data-layout="button_count">
          </div>
        </li>
        */}

        <div>
          <a
            href="https://twitter.com/share?ref_src=twsrc%5Etfw"
            className="twitter-share-button"
            data-text={ Const.description }
            data-url={ url }
            data-lang="ja"
            data-show-count="true"
          />
        </div>

        <div>
          <a
            href="https://b.hatena.ne.jp/entry/"
            className="hatena-bookmark-button"
            data-hatena-bookmark-layout="basic-label-counter"
            data-hatena-bookmark-lang="ja"
            title="このエントリーをはてなブックマークに追加"
          >
            <img
              src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
              alt="このエントリーをはてなブックマークに追加"
              width="20"
              height="20"
              style={{ border: 'none' }}
            />
          </a>
        </div>

        <div>
          <LineShareButton
            className="snsshares__line"
            url={ url }
            title={ title }
          >
            <span>LINE</span>
          </LineShareButton>
        </div>

        <div>
          <a
            data-pocket-label="pocket"
            data-pocket-count="horizontal"
            className="pocket-btn"
            data-lang="ja"
            data-save-url={ url }
            data-pocket-align="left"
          />
        </div>
      </div>
    </>
  )
})

export default SnsShares
